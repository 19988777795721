import React, { ReactElement } from 'react';

function App(): ReactElement {

  return (
    <p style={{margin: 'auto', padding: '10%', textAlign: 'center', fontSize: 72}}>WIP</p>
  );
}

export default App;
